import API from "@/config/api";
import service from "@/utils/service";

const TaskService = {
  createTask: async (payload: any = {}) => {
    try {
      const res = await service.post(`${API.TASK_ACTIONS}`, payload);
      return res;
    } catch (error) {
      return error;
    }
  },

  editTaks: async(payload: any = {}, taskId: string) => {
    try {
      const res = await service.put(`${API.TASK_ACTIONS}/${taskId}`, payload);
      return res;
    } catch (error) {
      return error;
    }
  },
  getTasks: async (payload: any = {}) => {
    try {
      const res = await service.get(`${API.MY_TASKS}`);
      return []
    } catch (error) {
      return error;
    }
  },

  getTaskDetails: async (taskId: string) => {
    try {
      const res = await service.get(`${API.TASK_ACTIONS}/${taskId}`);
      return res.data;
    } catch (error) {
      return error;
    }
  },

  deleteTask: async(taskId: string)=>{
    try {
      const res = await service.delete(`${API.TASK_ACTIONS}/${taskId}`);
      return res.data;
    } catch (error) {
      return error;
    }
  },
  fetchAssignees: async (taskId: string) =>{
    try {
      const res = await service.get(`${API.TASK_ACTIONS}/${taskId}/assignees`);
      return res.data;
    } catch (error) {
      return error;
    }
  },
  uploadDoc: async (payload: any = {}, docId: string) => {
    // const payload={};
    try {
      const res = await service.post(
        `${API.UPLOAD_DOC}/${docId}/docs`,
        payload,{
          // headers:{
          //   'Content-Type': `multipart/form-data; boundary=`,
          // }
        }
      );
      return res;
    } catch (error) {}
  },
};

export default TaskService;
