import { generateRandomId } from "@/utils/functions";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface DragState {
  list: any;
  item: any;
  fields: any;
  templates: any;
  formName?:any;
}

const initialState: DragState = {
  list: { [generateRandomId()]: [] },
  item: [],
  fields: [],
  templates: [],
};

const dragSlice = createSlice({
  name: "drag",
  initialState,
  reducers: {
    addList: (state) => {
      const newListId = generateRandomId();
      state.list[newListId] = [];
    },

    makeListEmpty: (state, action) => {
      state.list = {};
    },

    setFormName: (state, action) => {
      state.formName = action.payload;
    },
    
    makeFormEmpty: (state) => {      
      state.item = [];
    },

    setItem: (state, action: PayloadAction<any>) => {
      state.item = [...state.item, action.payload];
    },

    reOrder: (state, action) => {
      state.item = action.payload;
    },

    setFeiled: (state, action) => {
      state.fields = action.payload;
    },

    setTemplate: (state, action) => {
      state.templates = action.payload;
    },

    updateItemFieldName: (
      state,
      action: PayloadAction<{ itemId: string; fieldName: string }>
    ) => {
      const { itemId, fieldName } = action.payload;
      const itemToUpdate = state.item.find((item: any) => item.id === itemId);
      if (itemToUpdate) {
        itemToUpdate.fieldName = fieldName;
        itemToUpdate.placeholder = `Enter ${fieldName}`;
        itemToUpdate.label = fieldName;
      }
    },

    deleteItem: (state, action: PayloadAction<string>) => {
      const itemIdToDelete = action.payload;
      state.item = state.item.filter((item: any) => item.id !== itemIdToDelete);
    },

    toggleMandatory: (state, action: PayloadAction<string>) => {
      const itemIdToToggle = action.payload;
      const itemToToggle = state.item.find(
        (item: any) => item.id === itemIdToToggle
      );
      if (itemToToggle) {
        itemToToggle.isRequired = !itemToToggle.isRequired;
      }
    },
  },
});

export const {
  setFeiled,
  addList,
  setItem,
  reOrder,
  makeListEmpty,
  setFormName,
  makeFormEmpty,
  updateItemFieldName,
  deleteItem,
  toggleMandatory,
  setTemplate,
} = dragSlice.actions;
export default dragSlice.reducer;