import { APP_NAME } from "@/constants/constants";
// import LogoImage from "@assets/svg/logo.svg";
import mainLogo from "@assets/svg/mainLogo.svg";
import React from "react";
import { Link, useLocation } from "react-router-dom";

type LogoProps = {
  style?: React.CSSProperties;
};

export const Logo: React.FC<LogoProps> = ({ style }) => {
  const location = useLocation();
  return (
    <Link
      className="flex items-center gap-2  object-cover cursor-pointer w-fit"
      style={{width:"fit-content"}}
      to={location.pathname.includes('personal-information')? '' :"/admin/dashboard/overview"}
    >
      <img
        src={mainLogo}
        alt={`${APP_NAME} logo`}
        className="w-[180px] h-[39px] object-cover"
      style={style}

      />
      {/* <p className="text-[22px] font-[poppins]" style={{ fontSize }}>
        <span className="text-PRIMARY-PRIMARY_50 font-semibold">
          {APP_NAME?.substring(0, 7)}
        </span>
        <span className="text-PRIMARY-PRIMARY_20 font-semibold">
          {APP_NAME?.substring(7, 13)}
        </span>
        <span className="text-PRIMARY-PRIMARY_20 font-light">
          {APP_NAME?.substring(13)}
        </span>
      </p> */}
    </Link>
  );
};
